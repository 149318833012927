import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// router
import { BrowserRouter as Router } from 'react-router-dom';
// get the config
import config from './config';
// style
// import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
// sentry.io logging
import { initSentry } from './lib/errorLib';

initSentry();

// app
const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));
// root
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <App />
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
