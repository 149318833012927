const config = {
  API: {
    REGION: 'me-south-1',
    URL: 'https://tbd.com',
    NAME: 'tbd-api',
    SESSION_CREDS: '/session/creds',
    SESSION_TICKET: '/session/ticket'

  },
  SENTRY_DSN: '',
  CDN: 'https://tbd.com/',
  APP_URL: 'https://tbd.com/',
  IS_LOCAL: false // set true only for local 
};
export default config;
