import configDev from './config-dev';
import configProd from './config-prod';
import configStaging from './config-stage';

let config = {}
if (process.env.REACT_APP_ENV === 'PRODUCTION') {
  config = configProd;
} else if (process.env.REACT_APP_ENV === 'STAGING') {
  config = configStaging;
} else {
  config = configDev;
}

export default config;
